@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap');

* {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #2c3d08;
}

::-webkit-scrollbar-thumb {
    background: #DDF1B5;
}

::selection {
    background: #ddf1b5;
    color: #000;
}

.font-para {
    font-family: "Varela Round", sans-serif !important;
}

.faq-row {
    background-color: #4c9540 !important;
    border-radius: 10px;
    box-shadow: 0 5px #DDF1B5 !important;
    color: #000 !important;
    font-family: Poppins, sans-serif;
    margin: 20px;
    padding-left: 20px !important;
}

.font-head {
    font-family: "Days One", sans-serif !important;
}

html {
    scroll-behavior: smooth;
}

.bg-img {
    // object-fit: cover !important;
    // background: url("https://i.imgur.com/x0Os7uK.png")no-repeat;
    // min-width: 100%;
    // min-height: 100%;
    // top: 0px !important;
}

.gellow {
    background: -moz-linear-gradient(90deg, #4c9540 0%, #4a7133 100%);
    background: -webkit-linear-gradient(90deg, #4c9540 0%, #4a7133 100%);
    background: -o-linear-gradient(90deg, #4c9540 0%, #4a7133 100%);
    background: -ms-linear-gradient(90deg, #4c9540 0%, #4a7133 100%);
    background: linear-gradient(90deg, #4c9540 0%, #4a7133 100%);
    -webkit-transition: all 3s !important;
    -moz-transition: all 3s !important;
    -ms-transition: all 3s !important;
    -o-transition: all 3s !important;
    transition: all 3s !important;
}

.gellow:hover {
    background: -moz-linear-gradient(270deg, #4c9540 0%, #4a7133 100%);
    background: -webkit-linear-gradient(270deg, #4c9540 0%, #4a7133 100%);
    background: -o-linear-gradient(270deg, #4c9540 0%, #4a7133 100%);
    background: -ms-linear-gradient(270deg, #4c9540 0%, #4a7133 100%);
    background: linear-gradient(270deg, #4a7133 100%, #4c9540 0%);
}

.lights {
    padding: 10px;
    -webkit-transition: box-shadow 300ms ease;
    transition: box-shadow 300ms ease;
    margin: 5px;
    color: #fff;
    border-radius: 10%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

button:disabled {
    opacity: 0.7 !important;
    cursor: not-allowed !important;
}

.timer h1 {
    display: inline-block;
    padding-left: 15px;
    text-align: center;
}

.timer {
    color: #DDF1B5;
    float: none;
    margin: 0 auto;
}

.timer small {
    font-weight: 300 !important;
    font-size: 11px !important;
}

.timer strong {
    font-size: 40px !important;
}

@media only screen and (max-width: 600px) {
    .timer {
        text-align: center !important;
        width: 100% !important;
    }
}